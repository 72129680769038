<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Crie uma promoção para clientes">
        <b-card-text>Preencha o formulário para gerar uma promoção</b-card-text>
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group>
              <label
                class="mt-1"
                for="promotion-name"
              >Digite o nome da promo</label>
              <b-form-input
                v-model="promotion.name"
                icon-no-border
                class="input-rounded-full"
              />
              <label
                class="mt-1"
                for="promotion-title"
              >Digite o título da promo</label>
              <b-form-input
                v-model="promotion.title"
                icon-no-border
                class="input-rounded-full"
              />
              <label
                class="mt-1"
                for="promotion-description"
              >Digite a descrição da promo</label>
              <b-form-input
                v-model="promotion.description"
                icon-no-border
                class="input-rounded-full"
              />
              <label
                class="mt-1"
                for="promotion-group_name"
              >Digite nome groupo da promo</label>
              <b-form-input
                v-model="promotion.group_name"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group>
              <label for="group-type">Relacionada ao depósito?</label>
              <b-form-checkbox
                v-model="promotion.deposit_related"
                switch
                name="group-type"
                value="1"
                :unchecked-value="0"
                class="mt-1"
                size="md"
              >
                <span v-if="!promotion.deposit_related">Não</span><span v-else>Sim</span>
              </b-form-checkbox>
            </b-form-group>
            <div class="w-full" />
            <b-form-group>
              <label for="group-type">Valor a ser liberado em <span
                v-if="!promotion.deposit_related"
                class="text-success"
              >R$</span><span
                v-else
                class="text-success"
              >%</span></label>
              <b-form-input
                v-model="promotion.value"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
            <div class="w-full" />
            <b-form-group>
            <label for="group-type">Multiplicador (Rollover)</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  variant="outline-primary"
                  @click="promotion.multiplier =promotion.multiplier-5"
                >
                  -
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="promotion.multiplier"
                class="text-center font-weight-bolder"
                min="0"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="promotion.multiplier =promotion.multiplier+5"
                >
                  +
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <label for="group-type">Ordem de depósito</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  variant="outline-primary"
                  @click="promotion.deposit_count--"
                >
                  -
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="promotion.deposit_count"
                class="text-center font-weight-bolder"
                min="0"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="promotion.deposit_count++"
                >
                  +
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
              <label for="group-type">Valor mínimo de deposito</label>
              <b-form-input
                v-model="promotion.min_amount_deposit"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
            <div class="w-full" />
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group>
              <label for="group-type">Somente usuários sem saque?</label>
              <b-form-checkbox
                v-model="promotion.no_withdraw_required"
                switch
                name="group-type"
                value="1"
                :unchecked-value="0"
                class="mt-1"
                size="md"
              >
                <span v-if="!promotion.no_withdraw_required">Não</span><span v-else>Sim</span>
              </b-form-checkbox>
            </b-form-group>
              <div class="w-full" />
              <label for="group-type">Qual Wallet a receber crédito?</label>
              <b-form-select
                v-if="all_wallet_types"
                v-model="promotion.wallet_type_id"
                size="lg"
              >
                <b-form-select-option
                  value=""
                  disabled
                >
                  Selecione
                </b-form-select-option>
                <b-form-select-option
                  v-for="wallet in all_wallet_types"
                  :key="wallet.id"
                  :data="wallet"
                  :value="wallet.id"
                >
                  {{ wallet.code }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <div class="w-full" />
            <b-form-group>
              <label for="group-type">Tolerância</label>
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    variant="outline-primary"
                    @click="promotion.tollerance--"
                  >
                    -
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model="promotion.tollerance"
                  class="text-center font-weight-bolder"
                  min="0"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="promotion.tollerance++"
                  >
                    +
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="w-full" />
            <b-form-group>
              <label for="group-type">Ganho máximo</label>
              <b-form-input
                v-model="promotion.max_gain"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
            <b-form-group>
              <label for="group-type">PROMOCODE</label>
              <b-form-input
                v-model="promotion.promocode"
                icon-no-border
                class="input-rounded-full"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            offset-lg="10"
            lg="2"
          >
            <b-button
              class="act-button mt-2"
              variant="gradient-primary"
              @click="saveGroup"
            >
              Cadastrar promo
            </b-button>
          </b-col>

        </b-row>
        <alert-toast
          ref="warning-toast"
          :message="this.warningMessage"
          :variant="this.messageVariant"
        />

      </b-card>
      <b-card title="Grupos onde a promoção estará ativa">
        <b-col>
          <general-groups-list
            :key="rKey"
            :add-info="'userPage'"
            :searched-groups="promotion_groups"
            @showGroupModal="showGroupModal"
          />
        </b-col>
      </b-card>
      <promotion-add-group-modal
        ref="groupModal"
        :already-added="promotion_groups"
        @refreshGroups="reloadGroups"
      />
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BRow, BButton, BCardText, BCol, BLink, BFormInput, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInvalidFeedback, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import AlertToast from '@/components/misc/AlertToast.vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import PromotionAddGroupModal from '@/components/promotions/PromotionAddGroupModal.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormInvalidFeedback,
    underscore: _,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    AlertToast,
    LogoLoaderAnimation,
    BFormGroup,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BFormSelectOption,
    GeneralGroupsList,
    PromotionAddGroupModal,

  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      group: { type: 'STATIC' },
      promotion: { deposit_related: 0, tollerance: 0,deposit_count: 0,no_withdraw_required:0, multiplier: 20 },
      groupRules: [],
      warningMessage: null,
      messageVariant: null,
      rKey: 1,
      promotion_groups: null,
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  apollo: {
    all_wallet_types: {
      query: gql`query{
        all_wallet_types{
          id,
          code,
        }
      }`,
      client: 'walletClient',
    },
  },
  created() {
    // this.searchUser()
  },
  methods: {
    addRule(id) {
      this.groupRules.push(id)
    },
    removeRule(id) {
      this.groupRules = this.groupRules.filter(r => r != id)
    },
    saveGroup() {
      //   return
      if (this.promotion.value == null || this.promotion.wallet_type_id == null || this.promotion.title == null) {
        this.warningMessage = 'Preencha todos os dados!'
        this.messageVariant = 'warning'
        this.$refs['warning-toast'].showAlert()
      } else if (this.promotion_groups == null || this.promotion_groups.length < 1) {
        this.warningMessage = 'Promoção precisa de um grupo selecionado!'
        this.messageVariant = 'warning'
        this.$refs['warning-toast'].showAlert()
      } else {
        this.executePromoSave()
      }
    },
    executePromoSave() {
      this.$apollo.mutate({
        mutation: gql`mutation($name: String,
          $title: String,
          $description: String,
          $group_name:String,
          $deposit_related:Int,
          $value:String,
          $wallet_type_id:ID,
          $tollerance:Int,
          $multiplier:Int,
          $no_withdraw_required:Int,
          $promocode:String,
          $deposit_count:Int,
          $max_gain:Float,
          $min_amount_deposit:Int){
          createUserPromotion(
            name:$name,
            title:$title,
            description:$description,,
            group_name: $group_name,
            deposit_related:$deposit_related,
            value:$value,
            wallet_type_id:$wallet_type_id,
            tollerance:$tollerance,
            multiplier:$multiplier,
            max_gain:$max_gain,
            no_withdraw_required:$no_withdraw_required,
            promocode:$promocode,
            deposit_count:$deposit_count,
            min_amount_deposit:$min_amount_deposit,
          ){
            id
          }
        }`,
        variables: {
          name: this.promotion.name,
          title: this.promotion.title,
          description: this.promotion.description,
          group_name: this.promotion.group_name,
          deposit_related: this.promotion.deposit_related * 1,
          value: this.promotion.value,
          wallet_type_id: this.promotion.wallet_type_id,
          tollerance: this.promotion.tollerance * 1,
          multiplier: this.promotion.multiplier * 1,
          max_gain: this.promotion.max_gain * 1,
          promocode: this.promotion.promocode,
          deposit_count: this.promotion.deposit_count * 1,
          min_amount_deposit: this.promotion.min_amount_deposit * 1,
          no_withdraw_required: this.promotion.no_withdraw_required * 1,
        },
        client: 'identityClient',
      }).then(data => {
        this.warningMessage = 'Promoção adicionada com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
        this.savedPromotionId = data.data.createUserPromotion.id
        this.savePromotionGroups()
      }).catch(error => {
        console.error(error)
      })
    },
    savePromotionGroups() {
      _.each(this.promotion_groups, this.savePromotionGroupsFn)
    },
    savePromotionGroupsFn(e) {
      this.tmpGroup = e.id
      this.$apollo.mutate({
        mutation: gql`mutation($group_id: ID,$promotion_id: ID){
          createPromoGroupRelation(
            group_id : $group_id,
            promotion_id : $promotion_id,
          ){
            id
          }
        }`,
        variables: {
          promotion_id: this.savedPromotionId,
          group_id: this.tmpGroup,
        },
        client: 'identityClient',
      }).then(data => {
        this.warningMessage = 'Grupo adicionada com sucesso!'
        this.messageVariant = 'success'
        this.$refs['warning-toast'].showAlert()
      }).catch(error => {
        console.error(error)
      })
    },
    showGroupModal() {
      this.$refs.groupModal.$bvModal.show('promotion-group-modal')
    },
    reloadGroups(gArray) {
      this.promotion_groups = gArray
    },
  },
}
</script>

<style>

</style>
